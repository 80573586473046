<!-- =========================================================================================
  File Name: PartnerView.vue
  Description: Partner View page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/partner/pixinvent
========================================================================================== -->

<template>
  <div id="page-partner-view">

    <div v-if="partner_data" id="partner-data">
      <div class="knowledge-base-jumbotron rounded-lg"
           :style="{'background-image': 'url('+ photoUrl(partner_data.banner_url) +')'}"
           style="background-position: center">
        <div style="backdrop-filter: blur(25px)"
             class="knowledge-base-jumbotron-content lg:p-20 md:p-12 sm:p-8 p-4 mb-base rounded-lg">
          <div class="flex justify-between items-center">
            <h1 class="text-white" style="font-size: 60px">Welcome Back {{ partner_data.name }}</h1>
            <img :src="photoUrl(partner_data.logo_url)" style="max-width: 256px" height="128px">
          </div>
        </div>
      </div>

      <div class="vx-row match-height">
        <div class="vx-col w-1/4">
          <vx-card class="mb-base" title="Partner Owner">
            <div class="flex items-center align-middle">
              <vs-avatar :src="owner_details.photoURL" class="mr-3" size="35px"/>
              <h1>{{ owner_details.first_name }} {{ owner_details.last_name }}</h1>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-1/4">
          <vx-card class="mb-base" title="Your Role">
            <div class="flex items-center align-middle">
              <h1>{{ partner_role | title }}</h1>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-1/4">
          <vx-card class="mb-base" title="Partner Balance (USD)">
            <div class="flex items-center align-middle">
              <h1>{{ partner_data.wallet.balance_usd | currency }}</h1>
            </div>
          </vx-card>
        </div>


        <div class="vx-col w-1/4">
          <vx-card class="mb-base" title="Partner Staff">
            <div class="flex items-center align-middle">
              <h1>{{ managers_rich.length + sales_rich.length + 1 }}</h1>
            </div>
          </vx-card>
        </div>

        <!---
        <div class="vx-col w-1/4">
          <vx-card :title="partner_data.name" class="mb-base" style="min-height:60px;">
            <vs-button v-if="partner_role === 'owner' || partner_role === 'managers'" :to="`/partner/edit`" class="mr-4"
                       icon="icon-edit" icon-pack="feather">
              Edit
            </vs-button>
          </vx-card>
        </div>

        <div class="vx-col lg:w-1/2 w-full">
          <vx-card class="mb-base" title="Information">
            <table>
              <tr>
                <td class="font-semibold">Partner Name</td>
                <td>{{ partner_data.name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Partner Owner</td>
                <td>{{ owner_details }}</td>
              </tr>
              <tr v-if="partner_data.wallet && (partner_role === 'managers' || partner_role === 'owner')">
                <td class="font-semibold">USD Balance</td>
                <td>{{ partner_data.wallet.balance_usd | currency }}</td>
              </tr>
              <vs-divider/>
              <tr>
                <td class="font-semibold">Your Role</td>
                <td>{{ partner_role | title }}</td>
              </tr>
            </table>
          </vx-card>
        </div>



        <div class="vx-col lg:w-1/4 w-full">
          <vx-card class="mb-base" title="Partner Roster">
            <table>
              <tr>
                <td class="font-semibold">{{ owner_details }}</td>
                <td>Owner</td>
              </tr>
              <tr v-for="manager in managers_rich" :key="manager.id">
                <td class="font-semibold">{{ manager.full_name }}</td>
                <td>Manager</td>
              </tr>
              <tr v-for="sales in sales_rich" :key="sales.id">
                <td class="font-semibold">{{ sales.full_name }}</td>
                <td>Sales</td>
              </tr>
            </table>
          </vx-card>
        </div>


        <div class="vx-col w-1/2 xl:w-1/4">
          <vx-card class="mb-base" title="Partner Roster">
            <table>
              <tr>
                <td class="font-semibold">{{ owner_details.first_name }} {{ owner_details.last_name }}</td>
                <td>Owner</td>
              </tr>
              <tr v-for="manager in managers_rich" :key="manager.id">
                <td class="font-semibold">{{ manager.full_name }}</td>
                <td>Manager</td>
              </tr>
              <tr v-for="sales in sales_rich" :key="sales.id">
                <td class="font-semibold">{{ sales.full_name }}</td>
                <td>Sales</td>
              </tr>
            </table>
          </vx-card>
        </div>

                 --->
      </div>

      <div class="vx-row match-height">
        <div class="vx-col w-1/4">
          <vx-card class="mb-base" title="Total Students">
            <div class="flex items-center align-middle">
              <h1>{{ partner_data.statistics.students }}</h1>
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-1/4">
          <vx-card class="mb-base" title="Active Subscriptions">
            <div class="flex items-center align-middle">
              <h1>{{ partner_data.statistics.subscriptions }}</h1>
            </div>
          </vx-card>
        </div>

        <!--        <div class="vx-col w-1/4">-->
        <!--          <vx-card class="mb-base" title="Lifetime Revenue (USD)">-->
        <!--            <div class="flex items-center align-middle">-->
        <!--              <h1>${{ (partner_data.statistics.revenue).toFixed(2) }}</h1>-->
        <!--            </div>-->
        <!--          </vx-card>-->
        <!--        </div>-->
        <!--        <div class="vx-col w-1/4">-->
        <!--          <vx-card class="mb-base" title="Weekly Revenue (USD)">-->
        <!--            <div class="flex items-center align-middle">-->
        <!--              <h1>$0.00</h1>-->
        <!--            </div>-->
        <!--          </vx-card>-->
        <!--        </div>-->

        <div class="vx-col w-1/4">
          <vx-card class="mb-base" title="Average Student Exam Scores">
            <div class="flex items-center align-middle">
              <h1>80.1%</h1>
            </div>
          </vx-card>
        </div>
        <div class="vx-col w-1/4">
          <vx-card class="mb-base" title="Certified Students">
            <div class="flex items-center align-middle">
              <h1>0</h1>
            </div>
          </vx-card>
        </div>


      </div>

      <div class="vx-row">
        <div class="vx-col w-2/3">
          <user-list cardTitle="Student List" :partnerId="partner_data.id" partnerRole="student"/>
        </div>


        <div class="vx-col w-1/3">
          <vx-card class="mb-base" title="Partner Actions">

            <vs-button class="w-full mb-3" icon-pack="feather" icon="icon-edit"
                       @click="$router.push(`/partner/edit`)">
              Edit Partner
            </vs-button>
            <vs-button class="w-full mb-3" icon-pack="feather" icon="icon-shopping-bag" color="warning"
                       @click="$router.push(`/partner/products`)">
              Product Manager
            </vs-button>
            <vs-button class="w-full mb-3" icon-pack="feather" icon="icon-tag" color="success"
                       @click="$router.push(`/partner/coupons`)">
              Coupon Manager
            </vs-button>
            <vs-button class="w-full mb-3" icon-pack="feather" icon="icon-file" color="danger"
                       :disabled="!partner_data.document_link" :href="partner_data.document_link">
              Document Portal
            </vs-button>

          </vx-card>
        </div>
      </div>

      <div class="vx-row mt-5">
        <div class="vx-col w-full">
          <subscription-list cardTitle="Subscription List" :partnerId="partner_data.id"/>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import SubscriptionList from '../accounting/subscription-list/SubscriptionList';
import UserList from '@/views/apps/user/user-list/UserList';
export default {
  data() {
    return {
      partner_data: {
        wallet: {
          balance_usd: 0
        }
      },
      partner_not_found: false,
      attributes: {},
      owner_details: {},
      partner_role: 'none',

      managers_rich: [],
      sales_rich: [],
    };
  },
  components: {
    UserList,
    SubscriptionList,
  },
  methods: {
    photoUrl(id) {
      return `https://portal-cdn.rapid.education/partner-pictures/${id || 'default'}.jpeg`;
    },
    showError(exception) {
      this.$vs.loading.close();

      let error = 'An unknown error occurred while modifying this partner';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to update partner',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  created() {

    const partnerId = this.activeUserInfo.partner_id;

    this.$http.get(`partners/${partnerId}/summary`)
      .then(response => {

        if (response.data.data) {
          this.partner_data = response.data.data;
          if (!this.partner_data.wallet) {
            this.partner_data.wallet = {
              balance_usd: 0
            };
          }
          this.owner_details.id = this.partner_data.owner_id;

          if (this.partner_data.owner_id === this.activeUserInfo.id) {
            this.partner_role = 'owner';
          } else if (this.partner_data.managers_ids.filter(a => a === this.activeUserInfo.id).length > 0) {
            this.partner_role = 'managers';
          } else if (this.partner_data.sales_ids.filter(a => a === this.activeUserInfo.id).length > 0) {
            this.partner_role = 'sales';
          }

          this.partner_data.managers_ids.forEach(async managerId => {
            this.$http.get(`users/${managerId}`)
              .then(response => {
                if (response.status === 200) {
                  const data = response.data.data;
                  data.full_name = `${data.first_name} ${data.last_name}`;
                  this.managers_rich.push(data);
                }
              });
          });

          this.partner_data.sales_ids.forEach(async managerId => {
            this.$http.get(`users/${managerId}`)
              .then(response => {
                if (response.status === 200) {
                  const data = response.data.data;
                  data.full_name = `${data.first_name} ${data.last_name}`;
                  this.managers_rich.push(data);
                }
              });
          });

          this.$http.get(`users/${this.partner_data.owner_id}`)
            .then(response => {
              if (response.status === 200) {
                this.owner_details = response.data.data;
                const pictureId = this.owner_details.profile_picture || 'default';
                this.owner_details.photoURL = `https://portal-cdn.rapid.education/profile-pictures/${pictureId}.jpeg`;
              }
            });

        }

      })
      .catch(error => {

        if (error.response && error.response.status === 404) {
          this.partner_not_found = true;
        }

      });
  },
};

</script>

<style lang="scss">
#avatar-col {
  width: 15rem;
}

#page-partner-view {
  table {
    td {
      vertical-align: top;
      min-width: 190px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}
</style>
